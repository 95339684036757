import { gql } from '@apollo/client';

export const GET_PRICE = gql`
  query Query(
    $origin: Location!
    $serviceTypes: [String]!
    $companyId: ID!
    $destination: Location
    $isHourly: Boolean
    $via: [Location]
  ) {
    getPrice(
      origin: $origin
      service_types: $serviceTypes
      company_id: $companyId
      destination: $destination
      is_hourly: $isHourly
      via: $via
    ) {
      id
      service_type
      price
      currency
      distance
      duration
    }
  }
`;
