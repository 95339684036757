import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

const SearchResults = ({ data, onChange, mt, width = 370 }) => {
  return (
    <Flex
      position="absolute"
      direction="column"
      zIndex={2}
      rounded="md"
      borderWidth={1}
      overflow="auto"
      backgroundColor={'white'}
      shadow={'md'}
      w={width}
      mt={mt}
    >
      {data?.map((item: { description: string }, index: React.Key) => (
        <Text
          key={index}
          onClick={() => onChange(item)}
          cursor="pointer"
          p={2}
          m={0}
          color="black"
          fontSize={14}
          _hover={{
            backgroundColor: 'gray.100',
          }}
        >
          {item.description}
        </Text>
      ))}
    </Flex>
  );
};

export default SearchResults;
