import { gql } from '@apollo/client';

export const GET_CAR_SERVICES = gql`
  query GetCarServices($fleetName: String) {
    getCarServices(fleet_name: $fleetName) {
      id
      name
      type
      icon
      passenger_count
      fleet_name
    }
  }
`;
