import { useCallback, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { Loader } from '@googlemaps/js-api-loader';

const useAddressPredictions = (key: string, input: string) => {
  const [predictions, setPredictions] = useState([]);

  const autocomplete = useRef(null);

  if (typeof window !== 'undefined' && !autocomplete.current) {
    const loader = new Loader({
      apiKey: key,
      version: 'weekly',
      libraries: ['places', 'geometry'],
      language: 'en-GB',
      region: 'GB',
    });

    loader
      .load()
      .then((google) => {
        autocomplete.current = new google.maps.places.AutocompleteService();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const getPlacePredictions = (input) => {
    autocomplete.current?.getPlacePredictions(
      { input, types: ['geocode', 'establishment'] },
      (predictions) => {
        setPredictions(predictions);
      }
    );
  };

  const debouncedGetPlacePredictions = useCallback(
    debounce(getPlacePredictions, 500),
    []
  );

  useEffect(() => {
    debouncedGetPlacePredictions(input);
  }, [input, debouncedGetPlacePredictions]);
  return predictions;
};

export default useAddressPredictions;
